<template>
  <div>
    <div ref="beforeTable" class="pa-4 d-flex align-center">
      <h2>
        Usuários
      </h2>
      <v-text-field
        class="ml-4 flex-grow-1"
        filled
        dense
        hide-details
        v-model="search"
        placeholder="Filtro por por nome ou e-mail"
        prepend-inner-icon="mdi-magnify"
        clearable
      />
      <v-autocomplete
        class="mx-4 flex-grow-1"
        filled
        dense
        hide-details="auto"
        ref="filterCompanies"
        placeholder="Filtro por Empresa"
        :items="companies"
        :loading="loadingCompanies"
        item-text="name"
        item-value="id"
        v-model="selectedCompany"
        prepend-inner-icon="mdi-domain"
        clearable
      />
      <v-btn height="40px" color="primary" @click="$refs.userDialog.open()">
        Novo Usuário
      </v-btn>
    </div>
    <v-divider />
    <v-data-table
      ref="dataTable"
      :headers="headers"
      :items="filteredByCompany"
      sort-by="name"
      :loading="loading"
      :search="search"
      hide-default-footer
      disable-pagination
      fixed-header
      @click:row="$refs.userDialog.open($event)"
      loading-text="Carregando... Por favor, aguarde"
      no-results-text="Nenhum usuário encontrado"
      :height="tableHeight"
    >
      <template #no-data>
        <div v-if="tryAgain" class="py-5 d-flex flex-column align-center black--text">
          Erro ao carregar usuários
          <br/>
          <v-btn small depressed color="error" class="mt-4" @click="loadUsers()">
            tentar novamente
          </v-btn>
        </div>
        <template v-else>
          Nenhum usuário encontrado
        </template>
      </template>
      <template #[`item.companies`]="{ item }">
        <v-chip
          v-for="company in item.companies"
          :key="company.id"
          small
          label
          class="ma-1">
          {{ company.name }}
          <v-icon v-if="company.is_admin" right color="black">
            mdi-account-tie
          </v-icon>
        </v-chip>
      </template>
    </v-data-table>
    <ViewEditCreateUserDialog ref="userDialog" @updated="loadUsers()" />
  </div>
</template>

<script>
import ViewEditCreateUserDialog from "@/components/ViewEditCreateUserDialog.vue";

export default {
  name: "UserManagement",

  components: {
    ViewEditCreateUserDialog,
  },

  props: ["contentHeight"],

  data: () => ({
    selectedCompany: undefined,
    companies: [],
    loadingCompanies: false,
    loading: false,
    tryAgain: false,
    search: "",
    headers: [
      { text: "Nome", value: "name" },
      { text: "E-Mail", value: "email" },
      {
        text: "Empresa",
        value: "companies",
        sortable: false,
        filtrable: false,
      },
    ],
    users: [],
    spaceBeforeTable: 100,
  }),

  computed: {
    tableHeight() {
      return (this.contentHeight - 1 - this.spaceBeforeTable) + "px";
    },
    filteredByCompany() {
      if (!this.selectedCompany) {
        return this.users;
      }
      return this.users.filter((u) =>
        u.companies.map((c) => c.id).includes(this.selectedCompany)
      );
    },
    mobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
  },

  methods: {
    reload(){
      this.loadUsers();
      this.loadCompanies();
    },
    loadUsers() {
      this.loading = true;
      this.tryAgain = false;
      this.$axios
        .get("/users")
        .then((response) => {
          this.users = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.tryAgain = true;
          this.$showMessage("error", "Erro inesperado ao carregar Usuários");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    loadCompanies() {
      this.loadingCompanies = true;
      this.$axios
        .get("/companies/admin")
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => {
          this.$showMessage("error", "Erro inesperado ao carregar clientes");
        })
        .finally(() => {
          this.loadingCompanies = false;
        });
    },
  },

  mounted() {
    this.$nextTick(() => {
      this.spaceBeforeTable = this.$refs.beforeTable.clientHeight;
    });
  },
};
</script>
